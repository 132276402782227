import {
	CardAction,
	CardActionColor,
	CardActionEmit,
	CardActionType,
} from "@/interfaces/action";

export class CardActionEntity implements CardAction {
	type: CardActionType = CardActionType.SAVE;
	text: CardActionType = CardActionType.SAVE;
	disabled: Boolean = true;
	outlined: Boolean = false;
	color: CardActionColor = CardActionColor.SECONDARY;
	emit: CardActionEmit = CardActionEmit.SAVE;

	constructor(params: {
		type?: CardActionType;
		properties?: Partial<CardAction>;
	}) {
		if (params.type) {
			this.type = params.type;
		}

		if (params.properties) {
			this.type = params.properties.type || CardActionType.SAVE;
			this.text = params.properties.type || CardActionType.SAVE;
			this.disabled = params.properties.disabled || false;
			this.outlined = params.properties.outlined || false;
			this.color = params.properties.color || CardActionColor.SECONDARY;
			this.emit = params.properties.emit || CardActionEmit.SAVE;
		}
	}

	setDisabled(disabled: Boolean) {
		this.disabled = disabled;
	}

	setText(text: CardActionType) {
		this.text = text;
	}

	getBtnType() {
		return this.type === CardActionType.SUBMIT
			? CardActionType.SUBMIT
			: CardActionType.BUTTON;
	}

	getBtnDataCy() {
		return this.type;
	}

    getText(): CardActionType {
        return this.text;
    }
}

export const getCardActions: CardActionEntity[] = [
	new CardActionEntity({
		properties: {
			type: CardActionType.VIEW_LINE_ITEM,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.VIEW_LINE_ITEM,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.CANCEL,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.CANCEL,
			outlined: true,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.CLOSE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.CLOSE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.DOWNLOAD,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.DOWNLOAD,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.RUN,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.RUN,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SAVE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SAVE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SAVE_CONTINUE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SAVE_CONTINUE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SCHEDULER,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SCHEDULER,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SCHEDULER_CREATE,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SCHEDULER_CREATE,
		},
	}),
	new CardActionEntity({
		properties: {
			type: CardActionType.SUBMIT,
			color: CardActionColor.SECONDARY,
			emit: CardActionEmit.SUBMIT,
		},
	}),
];

export function getEnabledActions(actions: CardActionType[]) {
	return getCardActions.filter((a) => actions.includes(a.type));
}
