
//@ts-nocheck
import Vue from "vue";
import {
	AdvertiserDataUpdate,
	Category,
} from "@/interfaces/advertiser";
import Alertize from "@/components/Alertize.vue";
import { Notification } from "@/interfaces/proccess";
import {
	isRequired,
	isNumber,
	isDomain,
	isMinLength,
	isMaxLength,
	isMaxCustomLength,
} from "@/services/rule-services";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import CardTextFieldToolTip from "@/components/Content/CardTextFieldToolTip.vue";
import { mapActions, mapGetters } from "vuex";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import CardActions from "@/components/Content/CardAction.vue";
//@ts-check

export default Vue.extend({
	name: "AdvertiserCreate",
	props: {},
	components: {
		Alertize,
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		CardTextFieldToolTip,
		CardActions,
	},
	data: () => ({
		title: "Edit",
		valid: false,
		loading: false,
		message: "",
		type: "info",
		show_tooltip_app_bundle: false,
		show_tooltip_domain: false,
		
		advertiser: {
			id: "",
			name: "",
			category_id: "",
			app_bundle: "",
			domain: "",
			active: true,
			external_id: undefined,
		} as AdvertiserDataUpdate,

		readonly: false
	}),
	created() {},
	async mounted() {
		try {
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchCategories();
			const advertiser: AdvertiserDataUpdate = await this.dispatchShowAdvertiser();
			this.setAdvertiser(advertiser);
			await this.setLoadingData();
		} catch (error) {
			await this.setLoadingData();
			console.log("mounted", { error: error });
		}
		this.$refs.form.resetValidation();
	},
	computed: {
		...mapGetters("profile", ["isRolReport"]),
		getID(): Number {
			return Number(this.$route.params.id);
		},
		getCategories(): Category[] {
			return this.$store.state.advertiser.categories;
		},
		isAlertize(): Boolean {
			return this.$store.state.proccess.alertize;
		},
		getRules() {
			return {
				isRequired,
				isNumber,
				isDomain,
				isMinLength,
				isMaxLength,
				isMaxCustomLength,
			};
		},
		getErrors() {
			return this.$store.state.proccess.errors;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		getError(index: any) {
			return getError(this.getErrors, index);
		},
		setAdvertiser(advertiser: AdvertiserDataUpdate) {
			this.advertiser = {
				id: advertiser.id,
				external_id: advertiser.external_id?.toString(),
				name: advertiser.name,
				category_id: advertiser.category_id,
				domain: advertiser.domain || "https://",
				app_bundle: advertiser.app_bundle,
				active: advertiser.active,
			} as AdvertiserDataUpdate;
		},
		async validate() {
			let form = this.$refs.form;
			return await form.validate();
		},
		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{ root: true }
			);
		},
		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
			}
		},
		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.update();
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},
		handleCancel() {
			this.setNotification({ title: "", message: "", type: "" });
			this.$router.push({ name: "AdvertisersIndex" });
		},
		toggleTooltipAppBundle() {
			this.show_tooltip_app_bundle = !this.show_tooltip_app_bundle;
		},
		toggleTooltipDomain() {
			this.show_tooltip_domain = !this.show_tooltip_domain;
		},
		toggleStatus(status: boolean) {
			this.advertiser.active = Boolean(status);
		},
		async dispatchCategories() {
			return this.$store.dispatch("advertiser/getCategories", {
				root: true,
			});
		},
		async dispatchShowAdvertiser() {
			return this.$store.dispatch("advertiser/show", this.getID, {
				root: true,
			});
		},
		async update() {
			return this.$store.dispatch(
				"advertiser/update",
				{
					advertiser: this.advertiser,
					id: this.getID,
				},
				{
					root: true,
				}
			);
		},
	},
});
