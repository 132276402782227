export enum CardActionType {
	VIEW_LINE_ITEM = "view-line-item",
	SUBMIT = "submit",
	SAVE = "save",
	SAVE_CONTINUE = "save-continue",
	RUN = "run",
	SCHEDULER = "scheduler",
	SCHEDULER_CREATE = "scheduler-create",
	DOWNLOAD = "download",
	CANCEL = "cancel",
	CLOSE = "close",
	BUTTON = "button", 
    GENERATE = "generate",
}

export enum CardActionColor {
	SECONDARY = "secondary",
}

export enum CardActionEmit {
	VIEW_LINE_ITEM = "viewLine",
	SUBMIT = "submit",
	SAVE = "save",
	SAVE_CONTINUE = "save-continue",
	RUN = "run",
	SCHEDULER = "scheduled-report",
	SCHEDULER_CREATE = "create-scheduler",
	DOWNLOAD = "download",
	CANCEL = "cancel",
	CLOSE = "close",
}

export interface CardAction {
	type: CardActionType;
	text: CardActionType;
	disabled: Boolean;
	outlined: Boolean;
	color: CardActionColor;
	emit: CardActionEmit;
	setDisabled?(disabled: Boolean): void;
	setText?(text: CardActionType): void;
	getText(): string;
}
