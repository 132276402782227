
import Vue from "vue";
//@ts-ignore
import { CardAction } from "@/interfaces/action";
//@ts-ignore
import { getEnabledActions } from "@/models/CardAction";
//@ts-ignore
import { CardActionEntity } from "@/models/CardAction";
//@ts-ignore
import { CardActionType } from "@/interfaces/action";

export default Vue.extend({
	name: "CardActions",
	props: {
		showBtns: {
			type: Array,
			default: function () {
				return [];
			},
		},
		disabled: {
			type: Object,
			default: function () {
				return {};
			},
		},
		texts: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {},
	data: () => ({
		cardActions: [] as CardActionEntity[],
	}),
	async created() {
		let actions: CardActionEntity[] = this.getEnabledActions();
		this.cardActions = actions;
		await this.detectDisabled();
		await this.detectCustomText();
	},
	async mounted() {},
	computed: {},
	methods: {
		handleAction(type: any) {
			this.$emit("action", { type: type });
		},
		getEnabledActions(): CardAction[] {
			return getEnabledActions(this.showBtns);
		},
		async detectDisabled() {
			if (!this.disabled) return;
			for (const [key, value] of Object.entries(this.disabled)) {
				this.cardActions
					.find((a: { type: string }) => a.type === key)
					?.setDisabled(value as Boolean);
			}
		},
		async detectCustomText() {
			if (!this.texts) return;
			for (const [key, value] of Object.entries(this.texts)) {
				this.cardActions
					.find((a: { type: string }) => a.type === key)
					?.setText(value as CardActionType);
			}
		},
	},
	watch: {
		disabled: {
			async handler() {
				await this.detectDisabled();
				await this.detectCustomText();
			},
			deep: true,
			immediate: true,
		},
		showBtns: {
			async handler(val, oldVal) {
				let actions: CardActionEntity[] = this.getEnabledActions();
				this.cardActions = actions;
				await this.detectDisabled();
				await this.detectCustomText();
			},
			deep: true,
			immediate: true,
		}
	},
});
